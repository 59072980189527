import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Paragraph } from '@entur/typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "development-of-nod-backoffice-clients"
    }}>{`Development of NOD BackOffice Clients`}</h1>
    <h2 {...{
      "id": "using-nod"
    }}>{`Using NOD`}</h2>
    <p>{`This chapter describes the most central concepts for a PTO to use NOD. The chapter does not cover all details but aims instead to put the NOD PTO Interface Specification in context for a NOD PTO developer. The PTO interface is currently accessed through the PL4 application, and PL4 access is therefore required. The NOD-related functionality in PL4 is hereafter referred to as the PTIS-HUB.
For details on these services, consult the documentation.`}</p>
    <h2 {...{
      "id": "ptis-hub-nod-services"
    }}>{`PTIS-HUB NOD Services`}</h2>
    <p>{`Use of NOD is relatively simple. Orders that should be distributed by NOD can be added by calling the SOAP service described in `}{`[NOD_Services.doc - Document provided on request.]`}{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Service`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Comment`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`AddOrders`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Add one or more orders that should be distributed as an OrderGroup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`UpdateOrderGroup`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Modify the state of an OrderGroup. This has many restrictions and is mostly used for cancellation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GetOrderGroups`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Return the contents of OrderGroups. Typically used to check if an OrderGroups has been picked up, or used as a basis for a reconstruction.`}</td>
        </tr>
      </tbody>
    </table>
    <Paragraph style={{
      marginTop: '1rem'
    }} mdxType="Paragraph">
    The OrderDescription that is submitted through AddOrders is a Base64 encoded
    XML document indended for a specific Plugin. PL4 will retrieve the
    OrderDescription schema from this plugin and validate the sumbmitted
    OrderDescription according to this schema before a submission is accepted.
    The metadata submitted with the order (ActionType, CompanyID etc) is used by
    PL4 to select the correct Plugin.
    </Paragraph>
    <h2 {...{
      "id": "ptis-hub-registrar-services"
    }}>{`PTIS-HUB Registrar Services`}</h2>
    <p>{`Mobile QR Codes contain a cryptographically signed checksum. This checksum is calculated by the Mobile Ticket NOD Plugin. In order to verify these checksums during QR code scanning, the validator must obtain the same keys used for signing the QR code. These keys may be downloaded by a PTO through the PL4 RegsitrarServices by calling GetProtectedResources. This is the same service that the Plugin itself retrieves the keys from.`}</p>
    <h2 {...{
      "id": "submitting-an-order-to-nod-for-distribution"
    }}>{`Submitting an Order to NOD for Distribution`}</h2>
    <p>{`Read `}{`[Del 22 App D - Document provided on request.]`}{`.`}</p>
    <h2 {...{
      "id": "nod-transactions"
    }}>{`NOD Transactions`}</h2>
    <p>{`Read `}{`[Del 22 App D - Document provided on request.]`}{`.`}</p>
    <h2 {...{
      "id": "custom-reports"
    }}>{`Custom reports`}</h2>
    <p>{`This is mostly the properties submitted by the NOD Client at the time of pickup, and may be used for reports that need to know the location of the traveler, the Service Provider associated with the NOD Client etc.
The following example comes from a stationary Mobile Ticket APP:`}</p>
    <pre><code parentName="pre" {...{}}>{`<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<ns2:context xmlns:ns2="http://ioas.no/nod/queue/context">
    <property>
        <key>timestamp</key>
        <dateTime>2013-05-15T07:35:49.160Z</dateTime>
    </property>
    <property>
        <key>userAgent</key>
        <string>RuterBillett/1.0 (wp; OsVersion WinCE 7.10.8107; AppVersion 2)</string>
    </property>
    <property>
        <key>mediaSerialNumberID</key>
        <string>3298536270566</string>
    </property>
    <property>
        <key>locale</key>
        <string>no</string>
    </property>
    <property>
        <key>clientID</key>
        <string>DV5D4MCUXJ</string>
    </property>
    <property>
        <key>qrSize</key>
        <unsignedInt>480</unsignedInt>
    </property>
    <property>
        <key>orderGroupID</key>
        <string>999999</string>
    </property>
    <property>
        <key>capabilityCode</key>
        <string>000000001000000000000000000</string>
    </property>
    <property>
        <key>clientSecret</key>
    <byteArray>65303464356239642D363539642D343363632D393830392D303338316637343062376535</byteArray>
    </property>
</ns2:context>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      